import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About me`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Work`}</p>
    </blockquote>
    <p>{`Currently working at `}<a parentName="p" {...{
        "href": "https://demandscience.com/"
      }}>{`DemandScience`}</a>{`, remotely.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Education`}</p>
    </blockquote>
    <ul>
      <li parentName="ul">{`Masters of Science in Computer Science @ `}<a parentName="li" {...{
          "href": "https://www.gatech.edu/"
        }}>{`Georgia Tech`}</a></li>
      <li parentName="ul">{`Bachelor of Technology in Software Development @ `}<a parentName="li" {...{
          "href": "https://www.senecacollege.ca/home.html"
        }}>{`Seneca College`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      