import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="GitHub Open source Contributor" link="https://github.com/MajinBui" bg="linear-gradient(to right, #3A3A3A 0%, #DEDEDE 100%)" mdxType="ProjectCard">
  <img src="GitHub-Mark-32px.png" /> Open source Contributor
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      